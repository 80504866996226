define("discourse/plugins/discourse-user-notes/discourse-user-notes/connectors/user-card-post-names/show-user-notes-on-card", ["exports", "discourse/lib/text", "discourse-common/lib/get-owner", "I18n", "discourse/plugins/discourse-user-notes/discourse-user-notes/lib/user-notes"], function (_exports, _text, _getOwner, _I18n, _userNotes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    shouldRender(args, component) {
      const {
        siteSettings,
        currentUser
      } = component;
      return siteSettings.user_notes_enabled && currentUser && currentUser.staff;
    },
    setupComponent(args, component) {
      const {
        user
      } = args;
      const count = user.user_notes_count || user.get("custom_fields.user_notes_count") || 0;
      component.setProperties({
        userNotesCount: count,
        emojiEnabled: component.siteSettings.enable_emoji,
        emojiUrl: (0, _text.emojiUrlFor)("pencil"),
        userNotesTitle: _I18n.default.t("user_notes.show", {
          count
        })
      });
    },
    actions: {
      showUserNotes() {
        this.parentView.parentView._close();
        const store = (0, _getOwner.getOwner)(this).lookup("service:store");
        const user = this.get("args.user");
        (0, _userNotes.showUserNotes)(store, user.id, count => {
          if (this.isDestroying || this.isDestroyed) {
            return;
          }
          this.set("userNotesCount", count);
        });
      }
    }
  };
});